<template>
	<div>
		<ZyroTabs
			class="tabs"
			:tabs="USER_STYLES_DRAWER_TABS"
			:current-tab="currentTab"
			@change="changeCurrentTab({
				drawer: USER_STYLES_DRAWER,
				tab: $event
			})"
		/>
		<Transition
			name="slide-right"
			mode="out-in"
		>
			<Component :is="currentTab.id" />
		</Transition>
	</div>
</template>

<script>
import { USER_STYLES_DRAWER_TABS } from '@/components/builder-drawers/drawers/constants';
import { useDrawerTabs } from '@/components/builder-drawers/drawers/use/useDrawerTabs';
import { USER_STYLES_DRAWER } from '@/store/builder/constants/drawers';

export default {
	components: {
		TypographyStyleList: () => import('@/components/builder-drawers/drawers/partials/stylesDrawer/typograpghy/TypographyStyleList.vue'),
		ColorSetsLibrary: () => import('@/components/builder-drawers/drawers/partials/stylesDrawer/colors/ColorSetsLibrary.vue'),
	},
	setup() {
		const {
			currentTabs,
			changeCurrentTab,
		} = useDrawerTabs();

		return {
			changeCurrentTab,
			currentTabs,
			USER_STYLES_DRAWER_TABS,
			USER_STYLES_DRAWER,
		};
	},
	computed: { currentTab: ({ currentTabs }) => currentTabs[USER_STYLES_DRAWER] },
};
</script>

<style lang="scss" scoped>
@import '@/components/builder-drawers/drawers/StylesDrawer.scss';

.tabs {
	margin-bottom: 16px;
}
</style>
